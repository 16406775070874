import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { BiWallet } from 'react-icons/bi';
import { RiMastercardFill } from 'react-icons/ri';
import { BiLogOut } from 'react-icons/bi';
import "../css/wallet.css";
import BuyCard from '../components/BuyCard.jsx';

function Wallet() {
  const navigate = useNavigate();
  const [showBuyCard, setShowBuyCard] = useState(false);

  useEffect(() => {
    // Verificar si hay un parámetro action=buyCard en la URL
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('action') === 'buyCard') {
      setShowBuyCard(true);
    }

    // Escuchar el evento del navbar móvil
    const handleBuyCardClick = () => {
      setShowBuyCard(true);
    };

    document.querySelector('.wallet-page')?.addEventListener('buyCardClick', handleBuyCardClick);

    return () => {
      document.querySelector('.wallet-page')?.removeEventListener('buyCardClick', handleBuyCardClick);
    };
  }, []);

  useEffect(() => {
    // Actualizar la URL cuando cambia showBuyCard
    const url = new URL(window.location);
    if (showBuyCard) {
      url.searchParams.set('action', 'buyCard');
    } else {
      url.searchParams.delete('action');
    }
    window.history.replaceState({}, '', url);
  }, [showBuyCard]);

  const handleWalletClick = () => {
    console.log('Wallet clicked');
    setShowBuyCard(false);
  };

  const handleTarjetasClick = () => {
    setShowBuyCard(true);
  };

  const handleLogout = () => {
    navigate('/quienes-somos');
  };

  return (
    <div className="wallet-page">
      <Navbar />
      <div className="wallet-content">
        {/* El sidebar solo se muestra en desktop */}
        {window.innerWidth > 768 && (
          <div className="wallet-sidebar">
            <div className="sidebar-buttons">
              <button className="sidebar-button" onClick={handleWalletClick}>
                <BiWallet className="button-icon" />
                <span>Wallet</span>
              </button>
              <button className="sidebar-button" onClick={handleTarjetasClick}>
                <RiMastercardFill className="button-icon" />
                <span>Comprar Tarjeta</span>
              </button>
            </div>
          </div>
        )}
        <div className="wallet-main-content">
          {showBuyCard ? (
            <BuyCard />
          ) : (
            <div className="wallet-iframe-container">
              <iframe
                src="https://wallet-transactions-plugin-frontend.vercel.app"
                title="UniqueTitle" 
                className="wallet-iframe"
                allow="clipboard-write"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Wallet; 