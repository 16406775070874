import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../images/logo.png";
import "../css/navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.querySelector('.navbar');
      const navbarLinks = document.querySelector('.navbar-links');
      
      if (isMenuOpen && navbar && navbarLinks) {
        if (!navbar.contains(event.target) || !navbarLinks.contains(event.target)) {
          setIsMenuOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    
    if (location.pathname === '/wallet' && path === '/wallet?action=buyCard') {
      const walletComponent = document.querySelector('.wallet-page');
      if (walletComponent) {
        const event = new CustomEvent('buyCardClick');
        walletComponent.dispatchEvent(event);
      }
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-logo">
            <img 
              src={logo} 
              alt="logo" 
              onClick={() => handleNavigation('/quienes-somos')} 
              style={{
                cursor: 'pointer',
                width: '180px',
                height: 'auto'
              }} 
            />
          </div>
          
          <button className="hamburger-menu show-hamburger" onClick={toggleMenu}>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </button>

          {isMenuOpen && (
            <button 
              className="close-menu-button" 
              onClick={toggleMenu}
              aria-label="Cerrar menú"
            />
          )}

          <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
            <button onClick={() => handleNavigation('/quienes-somos')} className="nav-link">
              Quiénes Somos
            </button>
            <button 
              onClick={() => handleNavigation('/wallet')} 
              className="nav-link"
            >
              Wallet
            </button>
            <button onClick={() => handleNavigation('/tarjetas')} className="nav-link">
              Tarjetas
            </button>
            {isMenuOpen && (
              <button 
                onClick={() => handleNavigation('/wallet?action=buyCard')} 
                className="nav-link"
              >
                Comprar Tarjeta
              </button>
            )}
            <button onClick={() => handleNavigation('/remesas')} className="nav-link">
              Remesas
            </button>
            <button onClick={() => handleNavigation('/guias-tutoriales')} className="nav-link">
              Guías
            </button>
            <button onClick={() => handleNavigation('/contacto')} className="nav-link">
              Contáctanos
            </button>
          </div>
        </div>
      </nav>
      
      {showIframe && (
        <div className="iframe-overlay" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{
            position: 'relative',
            width: '90%',
            maxWidth: '600px',
            height: '80vh',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '20px',
            zIndex: 10000
          }}>
            <button 
              onClick={() => setShowIframe(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'none',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                zIndex: 10001
              }}
            >
              ×
            </button>
            <iframe
              src="https://wallet-transactions-plugin-frontend.vercel.app"
              title="UniqueTitle" 
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                zIndex: 10000
              }}
              allow="clipboard-write"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar; 