import logo from "../images/logo.png";
import "../css/menu.css";
import Navbar from "./Navbar";
import { useNavigate } from 'react-router-dom';

function Menu() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/payment/history');
  };
  const handleLogout = () => {
    navigate('/login');
  };
  
  return (
    <main className="flex min-h-screen flex-col items-center">
      <Navbar />
      <div className="menu-content">
        <div className="container-button">
          <button onClick={handleClick} className="transactions">Transacciones</button>
          <button onClick={handleLogout} className="logout">Cerrar sesión</button>
        </div>
      </div>
    </main>
  );
}

export default Menu;