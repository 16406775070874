import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import { HTTP } from "./http";


const countryPhoneCodes = {
  'AR': '+54',  // Argentina
  'BO': '+591', // Bolivia
  'BR': '+55',  // Brasil
  'CL': '+56',  // Chile
  'CO': '+57',  // Colombia
  'CR': '+506', // Costa Rica
  'CU': '+53',  // Cuba
  'DO': '+1',   // República Dominicana
  'EC': '+593', // Ecuador
  'SV': '+503', // El Salvador
  'GT': '+502', // Guatemala
  'HT': '+509', // Haití
  'HN': '+504', // Honduras
  'MX': '+52',  // México
  'NI': '+505', // Nicaragua
  'PA': '+507', // Panamá
  'PY': '+595', // Paraguay
  'PE': '+51',  // Perú
  'PR': '+1',   // Puerto Rico
  'UY': '+598', // Uruguay
  'VE': '+58',  // Venezuela
};

function ComprarTarjeta() {
  const [hasExistingRequest, setHasExistingRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    email: '',
    phoneNumber: '',
    occupation: '',
    monthlySalary: '0',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: '',
    postalCode: '',
    dniNumber: '',
    documentType: '',
  });

  const [documentationFormData, setDocumentationFormData] = useState({
    dniNumber: '',
    documentType: '',
    idFrontImage: null,
    idBackImage: null,
    passportFrontImage: null,
    walletAddress: ''
  });
  const [requestData, setRequestData] = useState(null);
  const [showCardPurchasedMessage, setShowCardPurchasedMessage] = useState(false);
  const [trackingCode, setTrackingCode] = useState('');
  const [showDocumentationMessage, setShowDocumentationMessage] = useState(false);
  const [showTransactionInProgress, setShowTransactionInProgress] = useState(false);
  const [showTransactionSuccess, setShowTransactionSuccess] = useState(false);
  const [externalPaymentPerUserWallet, setExternalPaymentPerUserWallet] = useState('');
  const [notification, setNotification] = useState(null);
  const [showWalletIframe, setShowWalletIframe] = useState(false);

  const checkExternalPaymentPerUserWallet = async () => {
    // const accounts = await provider?.request({ method: "eth_accounts" });
    // const walletAddress = accounts?.[0] || "";

    // if (!walletAddress) {
    //   return;
    // }

    // const formDataToSend = new FormData();
    
    // formDataToSend.append('walletAddress', walletAddress);

    // const response = await HTMLOutputElement.post(`/check/external/payment/per/user/wallet`, formDataToSend).then((response) => {
    //   if (response.data.result) {
    //     setExternalPaymentPerUserWallet(response.data.response);
    //   }
    // }).catch((error) => {
    //   console.error('Error checking external payment per user wallet:', error);
    // });

  }

  useEffect(() => {
    const checkExistingRequest = async () => {
      try {
        const userEmail = "test@gmail.com";
        
        const response = await HTTP.get(`/check/card/request/${userEmail}`);
        if (response.data.result) {
          setHasExistingRequest(response.data.response.exists);
          if (response.data.response.exists) {
            if (response.data.response.transactionSuccess) {
              // Show message that the card has already been purchased
              setShowCardPurchasedMessage(true);
            } else {
              setRequestData(response.data.response);
            }

            if (response.data.response.documentation) {
              setShowDocumentationMessage(true);
              setTrackingCode(response.data.response.trackingCode);
            } else {
              setShowDocumentationMessage(false);
              setTrackingCode('');
            }
          }
        }
      } catch (error) {
        console.error('Error checking existing request:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkExistingRequest();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent]),
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDocumentationInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setDocumentationFormData(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent]),
          [child]: value
        }
      }));
    } else {
      setDocumentationFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDocumentationFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocumentationFormData(prev => ({
        ...prev,
        [name]: files[0]
      }));
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000); // Hide after 3 seconds
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const formDataToSend = new FormData();
      
      formDataToSend.append('walletAddress', "test");
      
      // Add all text fields
      Object.keys(formData).forEach(key => {
        if (key !== 'idFrontImage' && key !== 'idBackImage') {
          formDataToSend.append(key, formData[key]);
        }
      });
      
      // Add country phone code
      if (formData.country) {
        formDataToSend.append('countryCode', countryPhoneCodes[formData.country]);
      }

      const response = await HTTP.post('/card/request', formDataToSend);

      console.log('Success:', response.data);
      showNotification('Solicitud enviada con éxito', 'success');

      window.location.reload();

    } catch (error) {
      console.error('Error:', error);
      showNotification('Error al enviar la solicitud. Por favor, intente nuevamente.', 'error');
    }
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'firstName':
      case 'lastName':
        return value.length >= 2;
      case 'email':
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case 'phoneNumber':
        return value.length >= 8;
      case 'country':
        return value !== '';
      case 'occupation':
        return value.length >= 2;
      case 'monthlySalary':
        return value >= 0;
      case 'addressLine1':
      case 'region':
      case 'city':
      case 'postalCode':
        return value.length >= 2;
      default:
        return true;
    }
  };

  const isFieldValid = (fieldName) => {
    return validateField(fieldName, formData[fieldName]);
  };

  const isFormValid = () => {
    const requiredFields = [
        'firstName',
        'lastName',
        'country',
        'email',
        'phoneNumber',
        'occupation',
        'monthlySalary',
        'addressLine1',
        'addressLine2',
        'region',
        'city',
        'postalCode'
    ];
    const allValid = requiredFields.every(field => {
        const valid = isFieldValid(field);
        return valid;
    });
    return allValid;
};
  const getInputClassName = (fieldName) => {
    const baseClasses = "mt-1 block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-black";
    const value = formData[fieldName];
    
    if (!value) return `${baseClasses} border-gray-300`;
    return isFieldValid(fieldName) 
      ? `${baseClasses} border-green-500`
      : `${baseClasses} border-red-500`;
  };

  const handleSignTransaction = async () => {
    setShowWalletIframe(true);
  };

const handleDocumentationSubmit = async (e) => {
  // e.preventDefault();

  // setShowTransactionInProgress(true);

  // try {
  //   const documentationData = new FormData();

  //   const accounts = await provider?.request({ method: "eth_accounts" });
  //   const walletAddress = accounts?.[0] || "";

  //   // Add DNI number and document type to documentationFormData
  //   documentationData.append('dniNumber', documentationFormData.dniNumber);
  //   documentationData.append('documentType', documentationFormData.documentType);
  //   documentationData.append('walletAddress', walletAddress);

  //   // Add images based on document type
  //   if (documentationFormData.documentType === 'DNI') {
  //     documentationData.append('idFrontImage', documentationFormData.idFrontImage);
  //     documentationData.append('idBackImage', documentationFormData.idBackImage);
  //   } else if (documentationFormData.documentType === 'PASSPORT') {
  //     documentationData.append('passportFrontImage', documentationFormData.passportFrontImage);
  //   }

  //   const response = await HTTP.post('/add/card/documentation', documentationData);
  //   console.log('Documentation submitted successfully:', response.data);
  //   showNotification('Documentación enviada con éxito', 'success');

  //   setShowTransactionInProgress(false);

  //   window.location.reload();

  //   // Optionally, you can reset the form or handle further actions here
  // } catch (error) {
  //   console.error('Error submitting documentation:', error);
  //   showNotification('Error al enviar la documentación. Por favor, intente nuevamente.', 'error');
  // }

  // setShowTransactionInProgress(false);
};

const formRequest = (
  <form onSubmit={handleSubmit} className="space-y-6" style={{
    marginBottom: '30px', 
    width: '100%',
    padding: window.innerWidth <= 768 ? '1rem' : '0'
  }}>
    <div className="space-y-4" style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>
          Primer Nombre *
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          className={getInputClassName('firstName')}
          required
        />
        {formData.firstName && !isFieldValid('firstName') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Nombre inválido ejemplo: Juan
          </p>
        )}
      </div>
      
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Primer Apellido *</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.lastName && !isFieldValid('lastName') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Apellido inválido ejemplo: Perez
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>
          Trabajo / Cargo / Puesto *
        </label>
        <input
          type="text"
          name="occupation"
          value={formData.occupation}
          onChange={handleInputChange}
          className={getInputClassName('occupation')}
          required
        />
        {formData.occupation && !isFieldValid('occupation') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Cargo inválido ejemplo: Desarrollador
          </p>
        )}
      </div>
      
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>
          Salario mensual aproximado en Dólares*
        </label>
        <input
          type="text"
          name="monthlySalary"
          value={formData.monthlySalary}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.monthlySalary && !isFieldValid('monthlySalary') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Salario inválido ejemplo: 1000
          </p>
        )}
      </div>
      
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>
          País *
        </label>
        <select
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          className={getInputClassName('country')}
          required
          style={{
            padding: '0.8rem 1rem',
            border: '2px solid rgba(255, 255, 255, 0.2)',
            borderLeft: '3px solid #f4b41a',
            borderRadius: '8px',
            fontSize: 'clamp(0.875rem, 2vw, 1rem)',
            backgroundColor: 'white',
            transition: 'all 0.3s ease',
            color: '#333'
          }}
        >
          <option value="">Seleccione un país</option>
          <option value="AR">Argentina</option>
          <option value="BO">Bolivia</option>
          <option value="BR">Brasil</option>
          <option value="CL">Chile</option>
          <option value="CO">Colombia</option>
          <option value="CR">Costa Rica</option>
          <option value="CU">Cuba</option>
          <option value="DO">República Dominicana</option>
          <option value="EC">Ecuador</option>
          <option value="SV">El Salvador</option>
          <option value="GT">Guatemala</option>
          <option value="HT">Haití</option>
          <option value="HN">Honduras</option>
          <option value="MX">México</option>
          <option value="NI">Nicaragua</option>
          <option value="PA">Panamá</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Perú</option>
          <option value="PR">Puerto Rico</option>
          <option value="UY">Uruguay</option>
          <option value="VE">Venezuela</option>
        </select>
        {formData.country && !isFieldValid('country') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            País inválido ejemplo: Argentina
          </p>
        )}
      </div>
      
      
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Correo electrónico *</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.email && !isFieldValid('email') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Correo electrónico inválido ejemplo: juanperez@gmail.com
          </p>
        )}
      </div>
      
      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Teléfono celular</label>
        <div className="mt-1 flex w-full rounded-md">
          <select
            name="country"
            value={formData.country}
            onChange={(e) => {
              const selectedCountry = e.target.value;
              setFormData({ ...formData, country: selectedCountry, phoneNumber: '' }); // Reset phone number on country change
            }}
            className="rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          >
            <option value="">Seleccione un país</option>
            {Object.entries(countryPhoneCodes).map(([code, phone]) => (
              <option key={code} value={code}>
                {phone} {code}
              </option>
            ))}
          </select>
          <input
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="block w-full rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
            placeholder="Número de teléfono"
            required
          /> 
        </div>
        {formData.phoneNumber && !isFieldValid('phoneNumber') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Número de teléfono inválido ejemplo: 1234567890
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Dirección Línea 1 *</label>
        <input
          type="text"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.addressLine1 && !isFieldValid('addressLine1') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Dirección inválida ejemplo: Calle 123
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Dirección Línea 2</label>
        <input
          type="text"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
        />
        {formData.addressLine2 && !isFieldValid('addressLine2') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Dirección inválida ejemplo: Calle 123
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Región *</label>
        <input
          type="text"
          name="region"
          value={formData.region}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.region && !isFieldValid('region') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Región inválida ejemplo: Buenos Aires
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}}>Ciudad *</label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.city && !isFieldValid('city') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Ciudad inválida ejemplo: Buenos Aires
          </p>
        )}
      </div>

      <div className="flex flex-col items-start">
        <label className="block text-sm font-medium text-gray-700 flex items-start" style={{color: "#666"}} >Código Postal *</label>
        <input
          type="text"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
          required
        />
        {formData.postalCode && !isFieldValid('postalCode') && (
          <p style={{textAlign: 'left', marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center'}}>
            <i className="fas fa-exclamation-circle mr-1" style={{color: '#d5d51b'}} />
            Código postal inválido ejemplo: 12345
          </p>
        )}
      </div>
    </div>
    <button
      type="submit"
      disabled={!isFormValid()}
      style={{
        width: '100%',
        marginTop: '2.5rem',
        marginBottom: '0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px',
        padding: '0.75rem 1.5rem',
        color: 'white',
        backgroundColor: isFormValid() ? '#4A90E2' : '#B0BEC5', // Color azul más atractivo
        cursor: isFormValid() ? 'pointer' : 'not-allowed',
        transition: 'background-color 0.3s ease, transform 0.2s ease',
        border: 'none',
        boxShadow: isFormValid() ? '0 4px 15px rgba(0, 0, 0, 0.3)' : 'none',
        fontSize: '1.1rem',
        fontWeight: '600',
      }}
      onMouseEnter={(e) => {
        if (isFormValid()) {
          e.currentTarget.style.transform = 'scale(1.05)';
          e.currentTarget.style.boxShadow = '0 6px 20px rgba(0, 0, 0, 0.3)'; // Aumentar sombra al pasar el mouse
        }
      }}
      onMouseLeave={(e) => {
        if (isFormValid()) {
          e.currentTarget.style.transform = 'scale(1)';
          e.currentTarget.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.3)'; // Restablecer sombra
        }
      }}
    >
      Enviar Solicitud
    </button>
  </form>
)

return (
  <main className="flex flex-col h-screen">
    <div className="flex flex-1 overflow-hidden" style={{
      background: 'white', 
      color: 'black', 
      width: '100%', 
      justifyContent: 'center', 
      alignItems: 'center',
      padding: window.innerWidth <= 768 ? '0' : '2rem'
    }}>
      {/* Agregar botón de cierre para móviles */}
      {window.innerWidth <= 768 && (
        <button 
          onClick={() => window.location.href = '/wallet'}
          className="mobile-close-button"
          style={{
            position: 'fixed',
            top: '6rem',
            right: '1rem',
            background: '#65C0CF',
            border: 'none',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            zIndex: 1000,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            transition: 'all 0.3s ease'
          }}
        >
          <span style={{
            position: 'relative',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <span style={{
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: '#190E5F',
              transform: 'rotate(45deg)',
              top: '50%',
              marginTop: '-1px'
            }}></span>
            <span style={{
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: '#190E5F',
              transform: 'rotate(-45deg)',
              top: '50%',
              marginTop: '-1px'
            }}></span>
          </span>
        </button>
      )}

      {!hasExistingRequest ? (
        <>
          <div className="flex flex-1 flex-col items-center justify-flex-start" style={{
            position: 'relative',
            backgroundColor: '#f8f9fa',
            borderBottom: '1px solid #eee',
            padding: window.innerWidth <= 768 ? '5rem 1rem 1rem' : '3rem 2rem 2rem',
            borderRadius: window.innerWidth <= 768 ? '0' : '1rem',
            width: '100%',
            marginTop: window.innerWidth <= 768 ? '3rem' : '0'
          }}>

            <h2 className="text-2xl font-bold text-[#12175D] bg-gray-100 p-4 rounded-lg shadow-md" style={{color: '#666'}}>
              Solicitud Tarjeta de Débito
            </h2>
            {formRequest}
            
            {/* Video section inside the main content div */}
            <div className="w-full max-w-4xl px-4 mb-8">
              <h2 className="text-2xl font-bold text-center text-[#12175D] mb-4" style={{color: "#666"}}>
                Tutorial de Compra de Tarjeta
              </h2>
              <div className="aspect-w-16 aspect-h-9" style={{width: '100%'}}>
                <iframe
                  style={{width: '100%', height: '100%'}}
                  src="https://www.youtube.com/embed/-XUxLyxJ6m0"
                  title="Tutorial Tarjeta" 
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>  
        </>
      ) : ( 
        <>
        {showDocumentationMessage ? (
            <>
                <div className="w-full h-full flex flex-1 flex-col bg-gray-50 items-center justify-start pt-16"  style={{overflow: 'scroll'}}>
                  <div className="w-11/12 max-w-4xl bg-white rounded-xl shadow-lg p-8">
                    <div className="border-b border-gray-200 pb-6 mb-6">
                      <h2 className="text-2xl font-bold text-[#12175D]">
                        Solicitud de tarjeta finalizada!
                      </h2>
                      <p className="text-gray-600 mt-2">Ya has comprado la tarjeta.</p>
                      <div className="bg-gray-50 p-6 rounded-xl hover:bg-gray-100 transition-all duration-200" style={{width: '100%'}}>
                        <div className="flex items-center space-x-3 mb-3">
                          {/* <KeyIcon className="w-5 h-5 text-[#12175D]" /> */}
                          <h3 className="font-semibold text-gray-700">Codigo de seguimiento</h3>
                        </div>
                        <div className="space-y-3">
                          <div style={{textAlign: 'left'}}>
                            <p className="text-sm text-gray-500">Código</p>
                            <input type="text" className="font-medium" value={trackingCode} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </>
          ) : (
          <>
            {showCardPurchasedMessage ? 
            (
              <>
                <div className="w-full h-full flex flex-1 flex-col bg-gray-50 items-center justify-start pt-16"  style={{overflow: 'scroll'}}>
                  <div className="w-11/12 max-w-4xl bg-white rounded-xl shadow-lg p-8">
                    <div className="border-b border-gray-200 pb-6 mb-6">
                      <h2 className="text-2xl font-bold text-[#12175D] bg-gray-100 p-4 rounded-lg shadow-md">
                        Solicitud de tarjeta en trámite
                      </h2>
                      <p className="text-gray-600 mt-2">Ya has comprado la tarjeta. No es necesario realizar otra solicitud.</p>
                    </div>
                    {/* New Section for DNI/Passport Information */}
                    <form onSubmit={handleDocumentationSubmit} className="space-y-4">
                      <div className="flex flex-col items-start">
                        <label className="block text-sm font-medium text-gray-700 flex items-start">Número de DNI *</label>
                        <input
                          type="text"
                          name="dniNumber"
                          value={documentationFormData.dniNumber}
                          onChange={handleDocumentationInputChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
                          required
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <label className="block text-sm font-medium text-gray-700 flex items-start">Tipo de Documento *</label>
                        <select
                          name="documentType"
                          value={documentationFormData.documentType}
                          onChange={handleDocumentationInputChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
                          required
                        >
                          <option value="">Seleccione un tipo de documento</option>
                          <option value="DNI">DNI</option>
                          <option value="PASSPORT">PASSPORT</option>
                        </select>
                      </div>
                      {documentationFormData.documentType === 'DNI' && (
                        <>
                          <div className="flex flex-col items-start">
                            <label className="block text-sm font-medium text-gray-700 flex items-start">Imagen del DNI (Frente) *</label>
                            <input
                              type="file"
                              name="idFrontImage"
                              onChange={handleDocumentationFileChange}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
                              required
                            />
                          </div>
                          <div className="flex flex-col items-start">
                            <label className="block text-sm font-medium text-gray-700 flex items-start">Imagen del DNI (Reverso) *</label>
                            <input
                              type="file"
                              name="idBackImage"
                              onChange={handleDocumentationFileChange}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
                              required
                            />
                          </div>
                        </>
                      )}
                      {documentationFormData.documentType === 'PASSPORT' && (
                        <div className="flex flex-col items-start">
                          <label className="block text-sm font-medium text-gray-700 flex items-start">Imagen del Pasaporte (Frente) *</label>
                          <input
                            type="file"
                            name="passportFrontImage"
                            onChange={handleDocumentationFileChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-black"
                            required
                          />
                        </div>
                      )}
                      <button
                        type="submit"
                        className="w-full mt-4 text-center justify-center items-center flex rounded-full px-6 py-3 text-white bg-[#12175D] hover:bg-[#1a2176] cursor-pointer"
                      >
                        Enviar Documentación
                      </button>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                backgroundColor: '#f9fafb', 
                alignItems: 'flex-start',
                justifyContent: 'center',
                padding: '1rem',
                overflow: 'auto',
                flexDirection: window.innerWidth <= 768 ? 'column' : 'row'
              }}>
                <div style={{ 
                  width: window.innerWidth <= 768 ? '100%' : '50%',
                  backgroundColor: 'white',
                  borderRadius: '0.75rem',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                  padding: '1rem',
                  marginBottom: window.innerWidth <= 768 ? '1rem' : '0'
                }}>
                  <div style={{ borderBottom: '1px solid #e5e7eb', paddingBottom: '1.5rem', marginBottom: '1.5rem' }}>
                    <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#12175D' }}>
                      Solicitud de Tarjeta Existente
                    </h2>
                    <p style={{ color: '#4b5563', marginTop: '0.5rem' }}>Detalles de su solicitud actual</p>
                    <p style={{ color: '#4b5563', marginTop: '0.5rem' }}>
                      El costo total es de $200 ($150 por la tarjeta y $50 por el envío).
                    </p>
                  </div>
                  
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
                    <div style={{ backgroundColor: '#f9fafb', padding: '1.5rem', borderRadius: '0.75rem', transition: 'background-color 0.2s', maxWidth: '400px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75rem' }}>
                        <h3 style={{ fontWeight: '600', color: '#4b5563' }}>Información Personal</h3>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Nombre Completo</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.firstName} {requestData?.lastName}</p>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Ocupación</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.occupation}</p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#f9fafb', padding: '1.5rem', borderRadius: '0.75rem', transition: 'background-color 0.2s', maxWidth: '400px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75rem' }}>
                        <h3 style={{ fontWeight: '600', color: '#4b5563' }}>Ubicación</h3>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>País</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.country}</p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#f9fafb', padding: '1.5rem', borderRadius: '0.75rem', transition: 'background-color 0.2s', maxWidth: '400px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75rem' }}>
                        <h3 style={{ fontWeight: '600', color: '#4b5563' }}>Contacto</h3>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Email</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.email}</p>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Teléfono</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.countryCode} {requestData?.phoneNumber}</p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#f9fafb', padding: '1.5rem', borderRadius: '0.75rem', transition: 'background-color 0.2s', maxWidth: '400px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75rem' }}>
                        <h3 style={{ fontWeight: '600', color: '#4b5563' }}>Información Adicional</h3>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Número de Teléfono</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.phoneNumber}</p>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Dirección Línea 1</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.addressLine1}</p>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Dirección Línea 2</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.addressLine2}</p>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Código Postal</p>
                        <p style={{ fontWeight: '500' }}>{requestData?.postalCode}</p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#f9fafb', padding: '1.5rem', borderRadius: '0.75rem', transition: 'background-color 0.2s', maxWidth: '400px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75rem' }}>
                        <h3 style={{ fontWeight: '600', color: '#4b5563' }}>Referido</h3>
                      </div>
                      <div style={{ marginBottom: '0.75rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Código</p>
                        <input type="text" style={{ fontWeight: '500' }} {...(localStorage.getItem('referrerToken') ? { value: localStorage.getItem('referrerToken') } : {})} readOnly={localStorage.getItem('referrerToken') ? true : false} />
                      </div>
                    </div>
                  </div>
                </div>
                {showWalletIframe && (
                  <div style={{ 
                    width: window.innerWidth <= 768 ? '100%' : '50%',
                    padding: '1rem',
                    position: window.innerWidth <= 768 ? 'relative' : 'absolute',
                    height: window.innerWidth <= 768 ? 'auto' : '600px'
                  }}>
                    <div style={{ 
                      position: 'relative',
                      width: '100%',
                      height: '600px',
                      overflow: 'hidden'
                    }}>
                      <iframe
                        src="https://wallet-transactions-plugin-frontend.vercel.app?price=200"
                        title="UniqueTitle" 
                        className="wallet-iframe"
                        allow="clipboard-write"
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        style={{
                          width: '100%',
                          height: '100%',
                          border: 'none'
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
    )}
  </div>
  {/* Transaction in progress popup */}
  {showTransactionInProgress && (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <p>Transacción en progreso...</p>
      </div>
    </div>
  )}

  {/* Transaction success popup */}
  {showTransactionSuccess && (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <p>¡Transacción enviada con éxito!</p>
      </div>
    </div>
  )}
  {/* {notification && (
    <Notification message={notification.message} type={notification.type} />
  )} */}
</main>
);
}

export default ComprarTarjeta; 